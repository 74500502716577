@media (max-width: 799px) {
  .containerAccount {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .displaySmallScreen {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 350px;
  }
  .borderBottomAccount {
    border-bottom: 2px solid #009e9b;
    margin: 10px 0px;
  }
  .boxInfosAccount {
    padding: 30px;
    box-shadow: 3px 3px 10px gray;
    border-radius: 10px;
    margin: 100px;
    background-color: white;
    position: relative;
  }
  .blueBoxInfosAccount {
    padding: 30px;
    box-shadow: 3px 3px 10px gray;
    border-radius: 10px;
    margin: 50px;
    background-color: #009e9b;
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    color: white;
    box-shadow: 3px 3px 10px gray;
  }
  .blueBorderBottomAccount {
    border-bottom: 2px solid white;
    margin: 10px 0px;
  }
  .blueBlocNameInfosAccount {
    display: flex;
    flex-direction: column;
  }
  .blueBlocAppointmentInfosAccount {
    display: flex;
    flex-direction: column;
  }
  .blueBtnAccount {
    border: none;
    padding: 10px 50px;
    background-color: white;
    border-radius: 5px;
    font-size: medium;
    cursor: pointer;
    color: #009e9b;
    position: absolute;
    bottom: -15px;
    box-shadow: 3px 3px 10px gray;
  }

  .iconAccount {
    position: absolute;
    z-index: -10;
    top: -30px;
    left: 30px;
    height: 20%;
  }
  .titleBoxInfosAccount {
    font-size: x-large;
    font-weight: 600;
  }
  .blocNameInfosAccount {
    display: flex;
  }
  .divNameInfosAccount {
    width: 150px;
  }
  .blocAppointmentInfosAccount {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .titleNameInfosAccount {
    font-size: smaller;
    margin: 15px 0px 5px 0px;
  }
  .textNameInfosAccount {
    font-weight: 600;
  }
  .btnAccount {
    border: none;
    padding: 10px 50px;
    background-color: #009e9b;
    border-radius: 5px;
    font-size: medium;
    cursor: pointer;
    color: white;
    position: absolute;
    bottom: -15px;
    box-shadow: 3px 3px 10px gray;
  }
  .containerHistorique {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px rgb(193, 193, 193);
    margin: 20px 0px;
  }
  .blocHistoriqueDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .containerBtnNewResa {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 50px;
  }
  .titleBtnNewResa {
    font-size: 25px;
    font-weight: 600;
    width: 60%;
    cursor: pointer;
  }
}
@media (min-width: 800px) and (max-width: 1279px) {
  .containerAccount {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .displaySmallScreen {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 350px;
  }
  .borderBottomAccount {
    border-bottom: 2px solid #009e9b;
    margin: 10px 0px;
  }
  .boxInfosAccount {
    padding: 30px;
    box-shadow: 10px 10px 40px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: 100px;
    background-color: white;
    position: relative;
  }

  .iconAccount {
    position: absolute;
    z-index: -10;
    top: -30px;
    left: 30px;
    height: 20%;
  }
  .titleBoxInfosAccount {
    font-size: x-large;
    font-weight: 600;
  }
  .blocNameInfosAccount {
    display: flex;
  }
  .divNameInfosAccount {
    width: 150px;
  }
  .blocAppointmentInfosAccount {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .titleNameInfosAccount {
    font-size: smaller;
    margin: 15px 0px 5px 0px;
  }
  .textNameInfosAccount {
    font-weight: 600;
  }
  .btnAccount {
    border: none;
    padding: 5px 30px;
    background-color: #009e9b;
    border-radius: 20px;
    font-size: medium;
    cursor: pointer;
    color: white;
    position: absolute;
    bottom: -15px;
  }
  .containerHistorique {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px rgb(193, 193, 193);
    margin: 20px 0px;
  }
  .blocHistoriqueDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .containerBtnNewResa {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 50px;
  }
  .titleBtnNewResa {
    font-size: 25px;
    font-weight: 600;
    width: 60%;
    cursor: pointer;

  }
}

@media (min-width: 1280px) {
  .containerAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-top: 300px;
  }

  .displaySmallScreen {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 200px;
  }
  .borderBottomAccount {
    border-bottom: 2px solid #009e9b;
    margin: 10px 0px;
  }
  .boxInfosAccount {
    padding: 30px 30px 20px 30px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 100px;
    background-color: white;
    max-height:350px;
    overflow:scroll;
  }

  .iconAccount {
    position: absolute;
    left: -30px;
    z-index: -10;
    top: 30px;
  }
  .titleBoxInfosAccount {
    font-size: x-large;
    font-weight: 600;
  }
  .blocNameInfosAccount {
    display: flex;
  }
  .divNameInfosAccount {
    width: 150px;
  }
  .blocAppointmentInfosAccount {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .titleNameInfosAccount {
    font-size: smaller;
    margin: 15px 0px 5px 0px;
  }
  .textNameInfosAccount {
    font-weight: 600;
  }
  .btnAccount {
    border: none;
    padding: 5px 30px;
    background-color: #009e9b;
    border-radius: 20px;
    font-size: medium;
    cursor: pointer;
    color: white;
    margin-top: 20px;
    
  }
  .containerHistorique {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px rgb(193, 193, 193);
    margin: 20px 0px;
  }
  .blocHistoriqueDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .containerBtnNewResa {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 50px;
  }
  .titleBtnNewResa {
    font-size: 25px;
    font-weight: 600;
    width: 60%;
    cursor: pointer;

  }
}
