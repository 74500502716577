@media (max-width: 799px) {
  .containerBookingFullScreen {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .phoneNumberBloc{
    position: absolute;
    top: 60px;
    height: 50px;
    width: 50px;
    background-color: #009e9b;
    border-radius: 10px 0px 0px 10px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phoneNumberImg{
    margin: 10px;
  }
  .phoneNumberText{
   display: none;
  }
  .logoModal {
    height: 100px;
  }
  .containerBooking {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .blocInfos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blueicon{
color: red;
  }
  .boxInfos {
    display: flex;
    z-index: 1000;
    margin-top: 40px;
    color: white;
    width: 80%;
    box-shadow: 2px 3px 15px gray;
    background-color: white;
  }
  .textAlignBlack {
    text-align: center;
    color: black;
  }
  .textAlignWhite {
    text-align: center;
    color: white;
  }
  .inputDateInfos {
    cursor: pointer;
    outline-offset: none;
    border: none;
    width: 60%;
  }

  .containerSlotAvailable {
    width: 80%;
    height: 39px;
    border: 3px solid black;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
  }
  .AllContainerSlotAvailable {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .containerSlotAvailableSelected {
    height: 39px;
    width: 80%;
    background-color: #009e9b;
    border: 3px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .blocSoltAvailable{
    display: flex;
    flex-direction: column;

  }

  .blocInfosReservation {
    padding:10px 20px ;
  }
  .containerDivInputAdress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .containerDateModal {
    display: flex;
    align-items: center;
    border-bottom: 3px solid black;
    padding: Opx;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .titleDivInfos {
    font-size: 30px;
    font-weight: 700;
    color: black;
    text-align: center;
    margin: 10px;
  }

  .inputDivInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    border-radius: 0px;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
  }
  .inputDivInfosDate2 {
    height: 20px;
    width: 40%;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
    border-radius: 0px;
  }
  .containerDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .containerCityAndZip{
    display: flex;
    justify-content: space-between;
  }
  .textInfosDate {
    font-size: large;
  }
  .dateInfos {
    margin-bottom: 5px;
    background-color: white;
    height: 50px;
    width: 40%;
    border-radius: 10px;
  }
  .textHour {
    color: black;
    position: absolute;
    bottom: -20px;
    font-size: small;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
  }
  .containerPubBetween {
    margin: 10px;
    text-align: center;
    margin-top: 80px;
    width: 90%;
  }
  .containerHidden {
    display: none;
  }
  .errorMessage {
    color: red;
    font-size: large;
    text-align: center;
    margin-bottom: 10px;
  }
  .errorBorderInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 60%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }
  .errorBorderInfosDate2 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 30%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }

  strong {
    color: #009e9b;
    font-weight: 900;
    font-size: medium;
  }
  h1 {
    height: 30px;
  }
  /* PUB */
  .titlePubInfos {
    font-size: xx-large;
    font-weight: 500;
    margin: 20px;
  }
  /* END PUB */
 

  .containerPubInfos {
    border-left: 3px solid black;
    width: 20%;
    text-align: left;
    margin:0px 0px 20px 0px;

  }
  .textExplanation {
    font-size: small;
  }
  .textDate {
    font-size: medium;
    color: #64697a;
  }
  .textBottomPrice {
    background-color: black;
    font-size: small;
    font-weight: 500;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
  }
  .btnBookingBlue {
    border: none;
    background-color: #009e9b;
    cursor: pointer;
    color: white;
    height: 300px;
    width: 150px;
  }
  /* MAPS */
  .containerBoxMaps {
    width: 100%;
    text-align: center;
  }
  .containerHidden {
    display: none;
  }
  .boxMaps {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  i.fas.fa-map-marker-alt {
    font-size: 30px;
    color: #104946;
  }
  .containerMpasExplanation {
    background-color: #009e9b;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  .titleMapsExplanation {
    font-size: x-large;
    font-weight: 600;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  .textMapsExplanation {
    font-size: large;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  /* BOOTLID */
  .containerBootlid {
    position: relative;
    margin: 50px 0px;
    width: 100%;
  }
  .containerHeaderBootlid {
    background-color: white;
    padding: 40px 40px 0px 40px;
    border-bottom: 5px solid #009e9b;
    margin: 30px 0px 70px 0px;
  }
  .boxBtnBootlid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
  }
  .titleBootlidInfos {
    font-size: x-large;
    font-weight: 500;
  }
  .bootlidExplanation {
    font-size: medium;
  }
  .btnBootlid {
    border: none;
    font-size: large;
    background-color: white;
    padding: 0px;
    border-bottom: 2px solid #009e9b;
  }

  a.btnBootlid {
    text-decoration: none;
    color: black;
  }
  /* STARS AND VOTE */
  .containerVoteCustomer {
    height: 100px;
    margin: 100px 0px 40px 0px;
    padding: 0px 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .voteWithStars {
    display: flex;
    margin: 20px;
  }
  .titleVote {
    font-size: xx-large;
    font-weight: 600;
    margin: 20px 0px;
    text-align: center;
  }
  .blocStars {
    margin-left: 15%;
  }
  .starsAndNote {
    display: flex;
    align-items: center;
    width: 200px;

  }
 
  .nameVote {
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .note {
    font-weight: 700;
    margin-left: 10px;
  }
  .containerStarsAndNote {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    height: 200px;
  }
  /* WHO ARE WE */
  .containerWhoAreWe {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .titleWhoAreWe {
    font-size: 200%;
    font-weight: 700;
    margin: 30px;
  }
  .textWhoAreWe {
    width: 60%;
    font-size: large;
    text-align: center;
    margin-bottom: 50px;
    line-height: 35px;
  }
  .associeted {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .boxAssocieted {
    position: relative;
    width: 100%;
  }
  .headbandAssocieted {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    z-index: 10;
  }
  .headbandOpacity {
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.8;
  }
  .nameAssocieted {
    font-size: x-large;
    font-weight: 700;
    margin-bottom: 5px;
  }
  /* END WHO ARE WE */

  .containerFooter {
    width: 100%;
    text-align: center;
  }
  .titleFooter {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 50px;
  }
  .arroundIcon {
    border: 1px solid #009e9b;
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .boxIconFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
  }
  .inconFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 50px 50px 50px;
  }
  .textIcon {
    font-size: x-large;
    font-weight: 700;
    color: #009e9b;
    margin-top: 20px;
  }
  /* END BOOTLID */
}
@media (min-width: 799px) and (max-width: 1279px) {
  .containerBookingFullScreen {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .phoneNumberBloc{
    position: absolute;
    top: 100px;
    height: 80px;
    width: 150px;
    background-color: #009e9b;
    border-radius: 5px 0px 0px 5px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phoneNumberImg{
    margin: 10px;
  }
  .phoneNumberText{
    color: white;
    font-size: large;
    font-weight: 600;
  }
  .logoModal {
    height: 100px;
  }
  .containerBooking {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .blocInfos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .boxInfos {
    z-index: 1000;
    display: flex;
    margin-top: -10px;
    color: white;
    width: 80%;
    box-shadow: 2px 3px 15px gray;
    background-color: white;
  }
  .textAlignBlack {
    text-align: center;
    color: black;
  }
  .textAlignWhite {
    text-align: center;
    color: white;
  }
  .inputDateInfos {
    cursor: pointer;
    outline-offset: none;
    border: none;
    width: 60%;
  }

  .containerSlotAvailable {
    width: 31%;
    height: 39px;
    border: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
  }
  .AllContainerSlotAvailable {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .containerSlotAvailableSelected {
    height: 39px;
    width: 32%;
    background-color: #009e9b;
    border: 3px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .blocSoltAvailable{
    display: flex;
    flex-direction: column;

  }

  .blocInfosReservation {
    padding:10px 100px ;
  }
  .containerDivInputAdress {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .containerDateModal {
    display: flex;
    align-items: center;
    border-bottom: 3px solid black;
    padding: Opx;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .titleDivInfos {
    font-size: 30px;
    font-weight: 700;
    color: black;
    text-align: center;
    margin: 10px;
  }

  .inputDivInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 100%;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
  }
  .inputDivInfosDate2 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 40%;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
  }
  .containerCityAndZip{
    display: flex;
    justify-content: space-between;
  }
  .containerDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .textInfosDate {
    font-size: large;
  }
  .dateInfos {
    margin-bottom: 5px;
    background-color: white;
    height: 50px;
    width: 40%;
    border-radius: 10px;
  }
  .textHour {
    color: black;
    position: absolute;
    bottom: -30px;
    font-size: small;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
  }
  .containerPubBetween {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  .containerHidden {
    display: none;
  }
  .errorMessage {
    color: red;
    font-size: large;
    text-align: center;
    margin-bottom: 10px;
  }
  .errorBorderInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 60%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }
  .errorBorderInfosDate2 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 30%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }

  strong {
    color: #009e9b;
    font-weight: 900;
    font-size: medium;
  }
  h1 {
    height: 30px;
  }
  /* PUB */
  .titlePubInfos {
    font-size: xx-large;
    font-weight: 500;
    margin: 20px;
  }
  /* END PUB */
  .blocBoxPubScrollable {
    display: flex;
    overflow: scroll;
    width: 70%;
    margin: 20px 0px 20px 0px;
  }

  .containerPubInfos {
    display: flex;
    border-left: 3px solid black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 30px 0px 10px;
  }
  .textExplanation {
    text-align: left;
    font-size: small;
  }
  .textDate {
    font-size: medium;
    color: #64697a;
  }
  .textBottomPrice {
    background-color: black;
    font-size: small;
    font-weight: 500;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
  }
  .btnBookingBlue {
    border: none;
    background-color: #009e9b;
    cursor: pointer;
    color: white;
    height: 300px;
    width: 150px;
  }
  /* MAPS */
  .containerBoxMaps {
    width: 100%;
    text-align: center;
  }
  .containerHidden {
    display: none;
  }
  .boxMaps {
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  i.fas.fa-map-marker-alt {
    font-size: 30px;
    color: #104946;
  }
  .containerMpasExplanation {
    background-color: #009e9b;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  .titleMapsExplanation {
    font-size: x-large;
    font-weight: 600;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  .textMapsExplanation {
    font-size: large;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  /* BOOTLID */
  .containerBootlid {
    position: relative;
    margin: 50px 0px;
    width: 100%;
  }
  .containerHeaderBootlid {
    background-color: white;
    width: 40%;
    padding: 40px 40px 0px 40px;
    border-bottom: 5px solid #009e9b;
    margin: 70px 0px 70px 400px;
  }
  .boxBtnBootlid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
  }
  .titleBootlidInfos {
    font-size: x-large;
    font-weight: 500;
  }
  .bootlidExplanation {
    font-size: medium;
  }
  .btnBootlid {
    border: none;
    font-size: large;
    background-color: white;
    padding: 0px;
    border-bottom: 2px solid #009e9b;
  }

  a.btnBootlid {
    text-decoration: none;
    color: black;
  }
  /* STARS AND VOTE */
  .containerVoteCustomer {
    height: 100px;
    margin: 40px 0px 40px 0px;
    padding: 0px 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .voteWithStars {
    display: flex;
  }
  .titleVote {
    font-size: xx-large;
    font-weight: 600;
    margin: 20px 0px;
    text-align: center;
  }
  .blocStars {
    margin-left: 15%;
  }
  .starsAndNote {
    display: flex;
    align-items: center;
    width: 200px;

  }
  .nameVote {
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .note {
    font-weight: 700;
    margin-left: 10px;
  }
  .containerStarsAndNote {
    display: flex;
    width: 100%;
    height: 200px;
  }
  /* WHO ARE WE */
  .containerWhoAreWe {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .titleWhoAreWe {
    font-size: 200%;
    font-weight: 700;
    margin: 30px;
  }
  .textWhoAreWe {
    width: 60%;
    font-size: large;
    text-align: center;
    margin-bottom: 50px;
    line-height: 35px;
  }
  .associeted {
    display: flex;
    width: 100%;
  }
  .boxAssocieted {
    position: relative;
    width: 33%;
  }
  .headbandAssocieted {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    z-index: 10;
  }
  .headbandOpacity {
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.8;
  }
  .nameAssocieted {
    font-size: x-large;
    font-weight: 700;
    margin-bottom: 5px;
  }
  /* END WHO ARE WE */

  .containerFooter {
    width: 100%;
    text-align: center;
  }
  .titleFooter {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 50px;
  }
  .arroundIcon {
    border: 1px solid #009e9b;
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .boxIconFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
  .inconFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 50px 50px 50px;
  }
  .textIcon {
    font-size: x-large;
    font-weight: 700;
    color: #009e9b;
    margin-top: 20px;
  }
  /* END BOOTLID */
 
}
@media (min-width: 1280px)  {
  .containerBookingFullScreen {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .phoneNumberBloc{
    position: absolute;
    top: 100px;
    height: 80px;
    width: 150px;
    background-color: #009e9b;
    border-radius: 5px 0px 0px 5px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phoneNumberImg{
    margin: 10px;
  }
  .phoneNumberText{
    color: white;
    font-size: large;
    font-weight: 600;
  }
  .logoModal {
    height: 100px;
  }
  .containerBooking {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .blocInfos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .boxInfos {
    display: flex;
    z-index: 1000;
    margin-top: -10px ;
    color: white;
    width: 80%;
    box-shadow: 2px 3px 15px gray;
    background-color: white;
  }
  .textAlignBlack {
    text-align: center;
    color: black;
  }
  .textAlignWhite {
    text-align: center;
    color: white;
  }
  .inputDateInfos {
    cursor: pointer;
    outline-offset: none;
    border: none;
    width: 60%;
  }

  .containerSlotAvailable {
    width: 31%;
    height: 39px;
    border: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
  }
  .blocSoltAvailable{
    display: flex;
  }
  .AllContainerSlotAvailable {
    width: 100%;
  }
  .containerSlotAvailableSelected {
    height: 39px;
    width: 32%;
    background-color: #009e9b;
    border: 3px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .blocInfosReservation {
    padding: 30px 15px;
    width: -webkit-fill-available;
  }
  .containerDivInputAdress {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .containerDateModal {
    display: flex;
    align-items: center;
    border-bottom: 3px solid black;
    padding: Opx;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .titleDivInfos {
    font-size: 30px;
    font-weight: 700;
    color: black;
    display: flex;
    align-items: center;
    margin-left: 50px;
  }

  .inputDivInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 100%;
    border-bottom: 3px solid black;
    margin-bottom: 20px;
  }
  .inputDivInfosDate2 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 40%;
    border-bottom: 3px solid black;
    margin-bottom: 20px;
  }
  .containerCityAndZip{
    display: flex;
    justify-content: space-between;
  }
  .containerDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .textInfosDate {
    font-size: large;
  }
  .dateInfos {
    margin-bottom: 5px;
    background-color: white;
    height: 50px;
    width: 40%;
    border-radius: 10px;
  }
  .textHour {
    color: black;
    position: absolute;
    bottom: -30px;
    font-size: small;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
  }
  .containerPubBetween {
    margin: 10px;
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .containerHidden {
    display: none;
  }
  .errorMessage {
    color: red;
    font-size: large;
    text-align: center;
    margin-bottom: 10px;
  }
  .errorBorderInfosDate1 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 60%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }
  .errorBorderInfosDate2 {
    height: 20px;
    cursor: pointer;
    border: none;
    width: 30%;
    border-bottom: 3px solid red;
    margin-bottom: 20px;
  }

  strong {
    color: #009e9b;
    font-weight: 900;
    font-size: medium;
  }
  h1 {
    height: 30px;
  }
  /* PUB */
  .titlePubInfos {
    font-size: xx-large;
    font-weight: 500;
    margin: 20px;
  }
  /* END PUB */
  .blocBoxPubScrollable {
    display: flex;
    overflow: scroll;
    width: 70%;
    margin: 20px 0px 20px 0px;
  }

  .containerPubInfos {
    display: flex;
    border-left: 3px solid black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 30px 0px 10px;
  }
  .textExplanation {
    text-align: left;
    font-size: small;
  }
  .textDate {
    font-size: medium;
    color: #64697a;
  }
  .textBottomPrice {
    background-color: black;
    font-size: small;
    font-weight: 500;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
  }
  .btnBookingBlue {
    border: none;
    background-color: #009e9b;
    cursor: pointer;
    color: white;
    height: 300px;
    width: 150px;
  }
  /* MAPS */
  .containerBoxMaps {
    width: 100%;
    text-align: center;
  }
  .containerHidden {
    display: none;
  }
  .boxMaps {
    height: 300px;
    width: 100%;
    display: flex;
  }
  i.fas.fa-map-marker-alt {
    font-size: 30px;
    color: #104946;
  }
  .containerMpasExplanation {
    background-color: #009e9b;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  .titleMapsExplanation {
    font-size: x-large;
    font-weight: 600;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  .textMapsExplanation {
    font-size: large;
    color: white;
    width: 80%;
    text-align: left;
    margin: 10px;
  }
  /* BOOTLID */
  .containerBootlid {
    position: relative;
    margin: 50px 0px;
    width: 100%;
  }
  .containerHeaderBootlid {
    background-color: white;
    position: absolute;
    top: 50px;
    margin-left: 15%;
    padding: 40px 40px 0px 40px;
    border-bottom: 5px solid #009e9b;
  }
  .boxBtnBootlid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
  }
  .titleBootlidInfos {
    font-size: x-large;
    font-weight: 500;
  }
  .bootlidExplanation {
    font-size: medium;
  }
  .btnBootlid {
    border: none;
    font-size: large;
    background-color: white;
    padding: 0px;
    border-bottom: 2px solid #009e9b;
  }

  a.btnBootlid {
    text-decoration: none;
    color: black;
  }
  /* STARS AND VOTE */
  .containerVoteCustomer {
    height: 100px;
    margin: 350px 0px 40px 0px;;
    padding: 0px 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .voteWithStars {
    display: flex;
  }
  .titleVote {
    font-size: xx-large;
    font-weight: 600;
    margin-right: 15%;
  }
  .blocStars {
    margin-left: 15%;
  }
  .starsAndNote {
    display: flex;
    align-items: center;
    width: 200px;

  }
  .nameVote {
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .note {
    font-weight: 700;
    margin-left: 10px;
  }
  .containerStarsAndNote {
    display: flex;
    width: 100%;
    height: 200px;
  }
  /* WHO ARE WE */
  .containerWhoAreWe {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .titleWhoAreWe {
    font-size: 200%;
    font-weight: 700;
    margin: 30px;
  }
  .textWhoAreWe {
    width: 60%;
    font-size: large;
    text-align: center;
    margin-bottom: 50px;
    line-height: 35px;
  }
  .associeted {
    display: flex;
    width: 100%;
  }
  .boxAssocieted {
    position: relative;
    width: 33%;
  }
  .headbandAssocieted {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    z-index: 10;
  }
  .headbandOpacity {
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.8;
  }
  .nameAssocieted {
    font-size: x-large;
    font-weight: 700;
    margin-bottom: 5px;
  }
  /* END WHO ARE WE */

  .containerFooter {
    width: 100%;
    text-align: center;
  }
  .titleFooter {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 50px;
  }
  .arroundIcon {
    border: 1px solid #009e9b;
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .boxIconFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
  .inconFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 50px 50px 50px;
  }
  .textIcon {
    font-size: x-large;
    font-weight: 700;
    color: #009e9b;
    margin-top: 20px;
  }
}
