@media (max-width: 799px) {
  .containerComments {
    width: 80%;
    background-color: #fafafa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 3px 3px 10px gray;
    margin: 20px;
  }
  .nameAndStars {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .nameComment {
    font-weight: 700;
    margin-right: 20px;
  }
}
@media (min-width: 800px) {
  .containerComments {
    width: 100%;
    background-color: #fafafa;
    border-radius: 10px;
    padding: 20px;
    margin-right: 30px;
    box-shadow: 3px 3px 10px gray;
  }
  .nameAndStars {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .nameComment {
    font-weight: 700;
    margin-right: 20px;
  }
}

