
@media (max-width: 1279px) {
 
  .containerBooking {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .blocInfos {
    width: 100%;
    display: flex;
  }

  .containerDivInput {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
  }
  .textBtnValidate {
    font-size: large;
    margin: 20px;
  }
  .dateInfos {
    font-size: x-large;
    margin-bottom: 5px;
    background-color: white;
  }
  .hourInfos {
    font-size: x-large;
    margin-bottom: 5px;
    width: 50px;
  }
  .containerDate {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .containerSlotAvailable {
    display: flex;
    align-items: center;
  }
  .titleSlotsInfos {
    font-size: x-large;
    color: white;
    margin: 10px;
  }
  .titleSlot {
    margin: 20px 0px 10px 0px;
    font-size: x-large;
    color: white;
  }

  .selectDivInfos {
    height: 40px;
    border: none;
    font-size: large;
    cursor: pointer;
  }

  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .textReglementation{
    color: black;
    text-align: center;
  }
  .textReglementationRed{
    color: red;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  .errorBorder {
    border: none;
    border-bottom: 3px solid red;
    padding: 10px 0px 10px 10px;
  }
  .errorSelectBorder {
    height: 40px;
    border: none;
    border-bottom: 3px solid red;
    font-size: large;
    cursor: pointer;
  }
  .btnValidateBlue {
    width: 100%;
    border: none;
    background-color: #009e9b;
    font-size: large;
    font-weight: 700;
    cursor: pointer;
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputDivInfos:focus-visible {
    outline: 0px;
  }
  .infosFor2results {
    display: flex;
  }

  .containerPub {
    height: 10vh;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .containerRound {
    display: flex;
    justify-content: flex-end;
  }

  .round {
    height: 79px;
    width: 70px;
    background-color: #c4c4c4;
    border-radius: 50%;
    margin: 50px 15px;
  }

  .footerApp {
    padding: 20px;
    box-sizing: border-box;
  }

}
/* Medium devices (tablets, 800px and 1279px) */
@media (min-width: 1280px) {
  .containerBooking {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .blocInfos {
    width: 100%;
    display: flex;
  }

  .containerDivInput {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
  }
  .textBtnValidate {
    font-size: x-large;
  }
  .dateInfos {
    font-size: x-large;
    margin-bottom: 5px;
    background-color: white;
  }
  .hourInfos {
    font-size: x-large;
    margin-bottom: 5px;
    width: 50px;
  }
  .containerDate {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .containerSlotAvailable {
    display: flex;
    align-items: center;
  }
  .titleSlotsInfos {
    font-size: x-large;
    color: white;
    margin: 10px;
  }
  .titleSlot {
    margin: 20px 0px 10px 0px;
    font-size: x-large;
    color: white;
  }

  .selectDivInfos {
    height: 40px;
    border: none;
    font-size: large;
    cursor: pointer;
  }

  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .errorBorder {
    border: none;
    border-bottom: 3px solid red;
    padding: 10px 0px 10px 10px;
  }
  .errorSelectBorder {
    height: 40px;
    border: none;
    border-bottom: 3px solid red;
    font-size: large;
    cursor: pointer;
  }
  .btnValidateBlue {
    width: 100%;
    border: none;
    background-color: #009e9b;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputDivInfos:focus-visible {
    outline: 0px;
  }
  .infosFor2results {
    display: flex;
  }

  .containerPub {
    height: 10vh;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .containerRound {
    display: flex;
    justify-content: flex-end;
  }

  .round {
    height: 79px;
    width: 70px;
    background-color: #c4c4c4;
    border-radius: 50%;
    margin: 50px 15px;
  }

  .footerApp {
    padding: 20px;
    box-sizing: border-box;
  }
}

/* Large devices (desktops, 1280px and up) */
@media (min-width: 1280px) {
  /* Rules */
}
