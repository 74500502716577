@media(max-width: 799px) {
  .containerPayment {
    display: flex;
    width: 100vw;
  }
  .return {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    flex: 1;
  }
  i.fas.fa-angle-left {
    color: #009e9b;
  }
  .textReturn {
    color: #009e9b;
    margin-left: 10px;
  }
  .form {
    margin-top: 50px;
  }
  .containerRecap {
    background-color: white;
    padding: 50px 100px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px grey;
    border-radius: 5px;
    margin-top: 20px;
    width: 80%;
  }
  .titleCheckout {
    font-size: x-large;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .blocInfosCheckout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .textCardCheckout {
    margin: 20px 0px 20px 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .containerImgCheckout {
    height: 50px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgCheckout {
    height: 30px;
  }
  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  .containerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoPayment {
    height: 120px;
    width: 100px;
  }
  .nameOnCard {
    display: flex;
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .container {
    /* remove the height dimensions when the page is done */
    display: flex;
  }

  .textCardPrice {
    margin-left: 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .price {
    margin: 0px 10px;
  }
  .titleCard {
    font-size: x-large;
    font-weight: 700;
    margin: 20px 10px;
  }
  .card {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .boxInfosDateCard {
    display: flex;
    width: 100%;
  }
  .borderInfosCard {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .borderInfosCardDate {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 50px 0px 0px;
    width: 100%;
    padding: 10px 5px;
  }

  .name {
    margin: 0px 10px 10px 0px;
    font-size: x-large;
  }
  .ContainerCguCgv {
    display: flex;
    align-items: center;
    width: 80%;
    margin-top: 40px;
  }
  .displayFlex {
    display: flex;
    align-items: center;
  }
  .containerPrice {
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  /* input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  } */
 
  .btnPaymentValidated {
    width: 100%;
    border: none;
    background-color: #009e9b;;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnPaymentNotValidated {
    width: 100%;
    border: none;
    background-color: #a8a8a8;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message {
    font-size: large;
  }
  .errorMessagePayment {
    color: red;
    font-size: large;
  }
  .imgGirl{
    display: none;
  }
}
/* medium devices (large desktops, 800px and 1279px) */
@media(min-width:800px)and (max-width: 1279px) {
  .containerPayment {
    display: flex;
    width: 100vw;
  }
  .return {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    flex: 1;
  }
  i.fas.fa-angle-left {
    color: #009e9b;
  }
  .textReturn {
    color: #009e9b;
    margin-left: 10px;
  }
  .form {
    margin-top: 50px;
  }
  .containerRecap {
    background-color: white;
    padding: 50px 100px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px grey;
    border-radius: 5px;
    margin-top: 20px;
    width: 80%;
  }
  .titleCheckout {
    font-size: x-large;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .blocInfosCheckout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .textCardCheckout {
    margin: 20px 0px 20px 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .containerImgCheckout {
    height: 50px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgCheckout {
    height: 30px;
  }
  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  .containerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoPayment {
    height: 120px;
    width: 100px;
  }
  .nameOnCard {
    display: flex;
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .container {
    /* remove the height dimensions when the page is done */
    display: flex;
  }

  .textCardPrice {
    margin-left: 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .price {
    margin: 0px 10px;
  }
  .titleCard {
    font-size: x-large;
    font-weight: 700;
    margin: 20px 10px;
  }
  .card {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .imgSecure {
    height: 50px;
    width: 200px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .boxInfosDateCard {
    display: flex;
    width: 100%;
  }
  .borderInfosCard {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .borderInfosCardDate {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 50px 0px 0px;
    width: 100%;
    padding: 10px 5px;
  }

  .name {
    margin: 0px 10px 10px 0px;
    font-size: x-large;
  }
  .ContainerCguCgv {
    display: flex;
    align-items: center;
    width: 80%;
    margin-top: 40px;
  }
  .displayFlex {
    display: flex;
    align-items: center;
  }
  .containerPrice {
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  /* input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  } */
 
  .btnPaymentValidated {
    width: 100%;
    border: none;
    background-color: #009e9b;;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnPaymentNotValidated {
    width: 100%;
    border: none;
    background-color: #a8a8a8;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message {
    font-size: large;
  }
  .errorMessagePayment {
    color: red;
    font-size: large;
  }
  .imgGirl{
    height: 50%;
    margin-top: 140Px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1280px) {
  .containerPayment {
    display: flex;
    width: 100vw;
  }
  .return {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    flex: 1;
  }
  i.fas.fa-angle-left {
    color: #009e9b;
  }
  .textReturn {
    color: #009e9b;
    margin-left: 10px;
  }
  .form {
    margin-top: 50px;
  }
  .containerRecap {
    background-color: white;
    padding: 50px 100px;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px grey;
    border-radius: 5px;
    margin-top: 20px;
    width: 80%;
  }
  .titleCheckout {
    font-size: x-large;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .blocInfosCheckout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .textCardCheckout {
    margin: 20px 0px 20px 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .containerImgCheckout {
    height: 50px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgCheckout {
    height: 30px;
  }
  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  .containerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoPayment {
    height: 120px;
    width: 100px;
  }
  .nameOnCard {
    display: flex;
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .container {
    /* remove the height dimensions when the page is done */
    display: flex;
  }

  .textCardPrice {
    margin-left: 20px;
    font-weight: 700;
    width: 200px;
    display: flex;
  }
  .price {
    margin: 0px 10px;
  }
  .titleCard {
    font-size: x-large;
    font-weight: 700;
    margin: 20px 10px;
  }
  .card {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .imgSecure {
    height: 50px;
    width: 200px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .boxInfosDateCard {
    display: flex;
    width: 100%;
  }
  .borderInfosCard {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 0px;
    width: 90%;
    padding: 10px 5px;
  }
  .borderInfosCardDate {
    height: 20px;
    border: none;
    border-bottom: 3px solid black;
    margin: 10px 50px 0px 0px;
    width: 100%;
    padding: 10px 5px;
  }

  .name {
    margin: 0px 10px 10px 0px;
    font-size: x-large;
  }
  .ContainerCguCgv {
    display: flex;
    align-items: center;
    width: 80%;
    margin-top: 40px;
  }
  .displayFlex {
    display: flex;
    align-items: center;
  }
  .containerPrice {
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  /* input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  } */
 
  .btnPaymentValidated {
    width: 100%;
    border: none;
    background-color: #009e9b;;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnPaymentNotValidated {
    width: 100%;
    border: none;
    background-color: #a8a8a8;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message {
    font-size: large;
  }
  .errorMessagePayment {
    color: red;
    font-size: large;
  }
  .imgGirl{
    height: 80% ;
}
}
