@media (min-width: 350px) {
  /* Rules */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .blocMessages {
    background-color: #afafaf;
    padding: 10px;
    margin: 10px 0px;
    color: green;
  }
  .displayNone {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Rules */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Rules */
}
