.containerInfos {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 50px;
}
.textInfos {
  font-size: 20px;
  font-weight: 500;
}
.marginRight {
  margin-right: 10px;
}
.search {
  margin-right: 20px;
}
