@media (max-width: 1279px) {
  .headerSmallScreen {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 40px;
    box-sizing: border-box;
    background-color: rgba(238, 238, 238, 0.9);
    position: fixed;
    width: 100%;
    z-index: 10000;
    justify-content: space-between;
    top: 0;
  }
  .logoRound {
    height: 30px;
    cursor: pointer;
  }
  .titleRound {
    height: 60%;
    opacity: 1;
  }
  .profilForLargeDevice {
    display: none;
  }
  .iconHeader {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #009e9b;
    font-size: 25px;
    right: 50px;
  }
  i.fas.fa-user {
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 1280px) {
  .headerSmallScreen {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 40px;
    box-sizing: border-box;
    background-color: rgba(238, 238, 238, 0.9);
    position: fixed;
    width: 100%;
    z-index: 10000;
    top: 0;
  }
  .logoRound {
    height: 30px;
    cursor: pointer;
  }
  .titleRound {
    height: 60%;
    margin-left: 10px;
    opacity: 1;
  }
  .profilForLargeDevice {
    color: #009e9b;
    position: absolute;
    right: 85px;
    font-size: large;
    font-weight: 400;
  }
  .iconHeader {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #009e9b;
    font-size: 25px;
    position: absolute;
    right: 50px;
  }
  i.fas.fa-user {
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
}
