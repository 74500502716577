@media (max-width: 767px) {
  .boxProfil {
    display: flex;
    flex-direction: column;
    color: white;
  }
  .containerLogin{
    width: 100%;
    padding: 10%;
  }
  .titleLogin{
    color: black;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .inputLogin {
    height: 20px;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
    width: 100%;
  }
 i.fas.fa-eye{
  color: black;
  position: absolute;
  z-index: 100;
  right: 0px;
 }
 i.fas.fa-eye-slash{
  color: black;
  position: absolute;
  z-index: 100;
  right: 0px;
 }
 .containerLoginInput{
  position: relative;
 }
 .containerBtnSubmitLogin{
 height: 50px;
 font-size: x-large;
 }
 .driver{
  display: none;
 }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Rules */
  .boxProfil {
    display: flex;
    flex-direction: column;
    color: white;
  }
  .containerLogin{
    width: 100%;
    padding: 10%;
  }
  .inputLogin {
    height: 20px;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid black;
    margin-bottom: 30px;
    width: 100%;
  }
  .titleLogin{
    color: black;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 20px;
  }
 i.fas.fa-eye{
  color: black;
  position: absolute;
  z-index: 100;
  right: 0px;
 }
 i.fas.fa-eye-slash{
  color: black;
  position: absolute;
  z-index: 100;
  right: 0px;
 }
 .containerLoginInput{
  position: relative;
 }
 .containerBtnSubmitLogin{
 height: 50px;
 font-size: x-large;
 }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Rules */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Rules */
}
