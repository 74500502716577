@media (max-width: 799px) {
  .containerSuccededOrder {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .containerRecapOrder {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* Rules */
  .blocInfosRecap {
    width: 60%;
    margin: 50px;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px gray;
    border-radius: 5px;
    text-align: center;
  }
  .containerTitleInfosRecap {
    display: flex;
    margin: 0px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
  }
  .titleInfosRecap {
    font-size: x-large;
    font-weight: 600;
    margin: 30px 0px;
  }
  .textInfosRecap {
    font-weight: 500;
  }
  .textRecapBooking {
    font-weight: 700;
    margin-top: 20px;
  }
}

/* Large devices (desktops, 800px and up) */
@media (min-width: 800px) {
  .containerSuccededOrder {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .containerRecapOrder {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* Rules */
  .blocInfosRecap {
    width: 60%;
    margin: 50px;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px gray;
    border-radius: 5px;
  }
  .containerTitleInfosRecap {
    display: flex;
    margin: 0px 0px 20px 0px;
  }
  .titleInfosRecap {
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .textInfosRecap {
    font-weight: 500;
  }
  .textRecapBooking {
    font-weight: 700;
    margin-top: 20px;
  }
}

