@media (max-width: 799px) {
  .containerInfosVehicle {
    display: flex;
    justify-content: space-between;
  }
  .imgInfosVehicle {
    display: none;
  }
  .displayReturn {
    display: flex;
    align-items: center;
  }
  i.fas.fa-angle-left{
font-size:40px;
margin-right: 10px;
cursor: pointer;

  }
  .containerInfosForInfosVehicle {
    display: flex;
    flex-direction: column;
    padding: 0px 50px;
    width: 100%;
    margin-bottom: 50px;
  }
  .titleInfosForInfosVehicle {
    font-size: x-large;
    font-weight: 700;
    margin: 5px 0px 20px 0px;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    border: none;
    border-bottom: 3px solid black;
    padding: 10px 0px 10px 10px;
    margin-bottom: 10px;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    width: 100%;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos:focus-visible {
    outline: 0px;
  }
  .selectDivInfos {
    height: 40px;
    border: none;
    border-bottom: 3px solid black;
    font-size: large;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  .blocBtnContinu {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
    box-shadow: -2px -2px 10px gray;
    box-sizing: border-box;
    z-index: 10000;
    background-color: white;
  }
  .btnValidate {
    height: 50px;
    width: 70%;
    border: none;
    background-color: #009e9b;
    font-size: large;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    color: white;
  }
  .passwordWithEye {
    position: relative;
    z-index: 200;
  }
  i.fas.fa-eye.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  i.fas.fa-eye-slash.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  .containerCheckbox {
    display: flex;
    align-items: flex-start;
    margin: 50px 0px 100px 0px;
  }
  input[type="checkbox"] {
    width: 52px;
    height: 52px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  }
  .errorPassword {
    color: red;
    font-size: large;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 799px) and (max-width: 1279px) {
  .containerInfosVehicle {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .imgInfosVehicle {
    height: 70%;
  }
  .displayReturn {
    display: flex;
    align-items: center;
  }
  i.fas.fa-angle-left{
font-size:40px;
margin-right: 10px;
cursor: pointer;
  }
  .containerInfosForInfosVehicle {
    display: flex;
    flex-direction: column;
    padding: 0px 50px;
    width: 100%;
    margin-bottom: 50px;
  }
  .titleInfosForInfosVehicle {
    font-size: x-large;
    font-weight: 700;
    margin: 5px 0px 20px 0px;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    border: none;
    border-bottom: 3px solid black;
    padding: 10px 0px 10px 10px;
    margin-bottom: 10px;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    width: 100%;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos:focus-visible {
    outline: 0px;
  }
  .selectDivInfos {
    height: 40px;
    border: none;
    border-bottom: 3px solid black;
    font-size: large;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  .blocBtnContinu {
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    z-index: 10000;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
    box-shadow: -2px -2px 10px gray;
  }
  .btnValidate {
    height: 50px;
    width: 70%;
    border: none;
    background-color: #009e9b;
    font-size: large;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    color: white;
  }
  .passwordWithEye {
    position: relative;
    z-index: 200;
  }
  i.fas.fa-eye.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  i.fas.fa-eye-slash.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  .containerCheckbox {
    display: flex;
    align-items: flex-start;
    margin: 50px 0px;
  }
  input[type="checkbox"] {
    width: 52px;
    height: 52px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  }
  .errorPassword {
    color: red;
    font-size: large;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 1280px) {
  .containerInfosVehicle {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .imgInfosVehicle {
    height: 100%;
  }
  .displayReturn {
    display: flex;
    align-items: center;
  }
  i.fas.fa-angle-left{
  font-size:40px;
  margin-right: 10px;
  cursor: pointer;
  }
  .containerInfosForInfosVehicle {
    display: flex;
    flex-direction: column;
    padding: 0px 50px;
    width: 100%;
    margin-bottom: 50px;
  }
  .titleInfosForInfosVehicle {
    font-size: x-large;
    font-weight: 700;
    margin: 5px 0px 20px 0px;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    border: none;
    border-bottom: 3px solid black;
    padding: 10px 0px 10px 10px;
    margin-bottom: 10px;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos {
    height: 20px;
    cursor: pointer;
    outline-offset: none;
    width: 100%;
  }
  .inputDivInfos:focus {
    outline-offset: none;
  }
  .inputDivInfos:focus-visible {
    outline: 0px;
  }
  .selectDivInfos {
    height: 40px;
    border: none;
    border-bottom: 3px solid black;
    font-size: large;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  .blocBtnContinu {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
    box-shadow: -2px -2px 10px gray;
    background-color: white;
    z-index: 1000;
  }
  .btnValidate {
    height: 50px;
    width: 70%;
    border: none;
    background-color: #009e9b;
    font-size: large;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    color: white;
  }
  .passwordWithEye {
    position: relative;
    z-index: 200;
  }
  i.fas.fa-eye.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  i.fas.fa-eye-slash.blackIcon {
    position: absolute;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
  }
  .containerCheckbox {
    display: flex;
    align-items: flex-start;
    margin: 50px 0px;
  }
  input[type="checkbox"] {
    width: 52px;
    height: 52px;
    background: white left top no-repeat;
    border: 3px solid rgb(43, 233, 254);
    cursor: pointer;
    margin: 0px 20px 0px 0px;
  }
  .errorPassword {
    color: red;
    font-size: large;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }
}
